import jsCookie from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { useStripQueryParams } from 'modules/history/useStripQueryParams'

import { handleLoginComplete, handleSignupComplete } from '../handleSuccess'

const LOGIN_SUCCESS_COOKIE_NAME = 'gamma_login_success'

export const useHandleLoginOrSignup = () => {
  const { query, isReady } = useRouter()

  const isLoginOrSignupHandled = useRef(false)

  useEffect(() => {
    // Only handle the login/signup flow in the presence of the explicit loginSuccess query param
    if (
      isReady &&
      jsCookie.get(LOGIN_SUCCESS_COOKIE_NAME) === 'true' &&
      !isLoginOrSignupHandled.current
    ) {
      const {
        newUser,
        isFirstWorkspace,
        welcomeDocId,
        signupFlow,
        newWorkspace,
        userId,
        workspaceId,
      } = query

      const isFirstWorkspaceBoolean = isFirstWorkspace === 'true'
      const newWorkspaceBoolean = newWorkspace === 'true'

      if (newUser === 'true') {
        handleSignupComplete({
          data: {
            userId: userId as string,
            workspaceId: workspaceId as string,
            isFirstWorkspace: isFirstWorkspaceBoolean,
            newWorkspace: newWorkspaceBoolean,
            signupFlow: signupFlow as string | '',
            welcomeDocId: welcomeDocId as string | '',
          },
          redirectMechanism: 'replaceState',
        })
      } else {
        handleLoginComplete({
          data: {
            userId: userId as string,
            workspaceId: workspaceId as string,
            isFirstWorkspace: isFirstWorkspaceBoolean,
            newWorkspace: newWorkspaceBoolean,
            signupFlow: signupFlow as string | '',
            welcomeDocId: welcomeDocId as string | '',
          },
          redirectMechanism: 'replaceState',
        })
      }
      isLoginOrSignupHandled.current = true
      jsCookie.remove(LOGIN_SUCCESS_COOKIE_NAME)
    }
  }, [isReady, query])

  useStripQueryParams({
    queryKey: [
      'newUser',
      'newWorkspace',
      'isFirstWorkspace',
      'welcomeDocId',
      'signupFlow',
      'userId',
      'workspaceId',
    ],
  })
}
