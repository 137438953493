import { GeneratorInput } from 'modules/ai/generator/types'
import { DEFAULT_CREDIT_COSTS } from 'modules/credits/constants'
import { ImageGenerateModel } from 'modules/media/apis/imageGenerate'

export type ExampleMemoJSON = {
  docId: string
  thumbnailUrl: string
  title: string
  disabled?: boolean
  isNew?: boolean
}

/**
 * Must enumerate all feature flags here
 */
export type FeatureFlags = {
  debugComments: boolean
  debugCardViewed: boolean
  debugLogging: boolean
  drawingBlock: boolean
  tldraw2: boolean
  uploadPDF: boolean
  highlight: {
    enabled: boolean
    enableStrictPrivacy: boolean
  }
  realUserMetrics: boolean
  rumSampleRate: number
  rumKeystrokeSampleRate: number
  cardViewedThreshold: number
  channelActivity: boolean
  customFonts: boolean
  docImport: boolean
  exportCard: boolean
  presentModeFlat: boolean // todo: remove
  blockReactions: boolean
  intercom: boolean
  shareFeedback: boolean
  dataSyncErrorThreshold: number
  dataSyncClockDriftTolerance: number
  screenshotsEnabled: boolean
  math: boolean
  offline: boolean
  offlineTest: boolean
  cardWidthOptions: boolean
  layoutResizing: boolean
  tableOfContentsBlock: boolean
  presentZoom: boolean
  linkMetadata: boolean
  presentFullWidth: boolean
  presentScaleFont: boolean
  renderCollapsedCards: boolean
  cardLayoutsEnabled: boolean
  presentFullScreen: boolean
  docFullWidth: boolean
  borderless: boolean
  aiAutocomplete: boolean
  aiRewrite: boolean
  aiCardDesigner: boolean
  aiPremiumModels: boolean
  aiWizard: boolean
  aiWizardLegacy: boolean
  aiWizardGenerateTimeout: number
  aiWizardAdvanced: boolean
  aiWizardOutlineMaxWait: number
  aiWizardAverageWaitGuided: number
  aiWizardAverageWaitAdvanced: number
  aiWizardStreaming: boolean
  aiWizardUnsplashImages: boolean
  aiWizardGoogleImages: boolean
  aiWizardBingImages: boolean
  aiWizardWebImages: boolean
  aiWizardUploadImages: boolean
  webImageProviderType: '1' | '2'
  aiChat: boolean
  aiChatJsx: boolean
  aiChatEditCardPrompt: boolean
  aiThemeWizard: boolean
  bulkUploadAccentImages: boolean
  publishing: boolean
  aiDebits: {
    generateImage: number
    generateCard: number
    editCard: number
    wizardCreation: number
    chatSuggestion: number
    autocomplete: number
    generateV2: number
    generateV2WithImages: number
    generateCardNotes: number
    removeBackground: number
    pptImportV2: number
  }
  'ai-credits': {
    enabled: false
    referralBonus: number
    signup: number
  }
  aiRequestTimeouts: {
    getOutlineForTopic: number
    classify: number
    basicReply: number
    suggestContent: number
    rateImages: number
  }
  aiRequestRetries: {
    wizard: number
    wizardAdvanced: number
    wizardStreaming: number
    chat: number
  }
  lexicaImageSearch: boolean
  webImageSearch: boolean
  docFormats: boolean
  signupRedirectTo: 'GENERATE' | 'GET_STARTED' | 'HOME' | 'CREATE'
  openAiStatus: 'DOWN' | 'DEGRADED' | 'NORMAL'
  docEditorForcePublicStatic: boolean
  cardTextComputedEnabled: boolean
  pptImport: boolean
  hasProAccess: boolean
  upsellUXEnabled_Pro: boolean
  upsellUXEnabled_Plus: boolean
  'plus-credits': {
    creditsToAdd: number
    enabled: boolean
  }
  monthlyBillingEnabled: boolean
  autoZoomToFit: boolean
  azureOpenAI: boolean
  passwordProtectedDoc: boolean
  passwordSignup: boolean
  docCustomCode: boolean
  aiGeneratedImages: boolean
  aiGeneratedImagesCount: number
  aiGeneratedImagesInWizard: boolean
  aiGeneratedImagesDefaultModel: ImageGenerateModel
  aiGeneratorDefaultImageProvider: 'web' | 'aiGenerated' | 'auto'
  aiGeneratorDefaultPresentationSettings: Partial<GeneratorInput['settings']>
  aiGeneratorDefaultWebpageSettings: Partial<GeneratorInput['settings']>
  aiGeneratedImagesAdvancedOptions: boolean
  automatedImageProvider: boolean
  permanentlyDeleteDoc: boolean
  i18nEnabled: boolean
  outageButterbar:
    | { enabled: false }
    | {
        enabled: true
        key: string
        header: string
        text?: string
        buttonText?: string
        buttonLink?: string
        pathnameRegexp?: string
      }

  filmstrip: boolean
  streamResumingEnabled: boolean
  sites: boolean
  sitesNav: boolean
  statsSmartLayouts: boolean
  pyramidSmartLayouts: boolean
  generatorLazyImages: boolean
  generatorGPT4: boolean
  generatorGPT4o: boolean
  generatorBigModelAlways: boolean
  imgProxy: boolean
  recaptcha: {
    enabled: boolean
    checkboxRiskLevels: Array<'low' | 'medium' | 'high' | 'unknown'>
  }
  mobileEditing: boolean
  churnkeyCancelFlowEnabled: boolean
  churnkeyFailedPaymentWallEnabled: boolean
  createGenerateV2: boolean
  cardNotes: boolean
  defaultAnnualBilling: boolean
  editorAnimationsEnabled: boolean
  editorAnimationsDefault: boolean
  siteAnimationsEnabled: boolean
  createSiteAi: boolean
  generateParallel: boolean
  generateParallelFree: boolean
  generateSerial: boolean
  anthropicGenerate: boolean
  geminiGenerate: boolean
  geminiProGenerate: boolean
  geminiGenerateOutline: boolean
  gpt4oMiniGenerate: boolean
  gpt4oMiniGenerateLong: boolean
  uploadThemeImagesToProd: boolean
  workspaceSettings: boolean
  addCard2: boolean
  imagen: boolean
  imagen3: boolean
  imagenFree: boolean
  imagenFlash: boolean
  dalle3: boolean
  dalle3Free: boolean
  playgroundModel: boolean
  playground3: boolean
  flux1Schnell: boolean
  flux1Dev: boolean
  flux1Pro: boolean
  ideogram: boolean
  ideogram2: boolean
  ideogramTurbo: boolean
  ideogramTurboFree: boolean
  sdxlModel: boolean
  pptImportThemes: boolean
  pptImportV2: boolean
  pptImportDebug: boolean
  importSlidePrompt: boolean
  importUrl: boolean
  importPdf: boolean
  enableCharts: boolean
  accentImageFit: boolean
  cardTemplatesPlaceholder: boolean
  hocuspocusOnAuthenticate: boolean
  knownGoodHostnames: string[]
  aiCardNotes: boolean
  editCard2: boolean
  editDocAI: boolean
  changelog: boolean
  filterContentErasingTrs: boolean
  export: boolean
  waitForGammaPersistenceMeta: boolean
  linksV2: boolean
  removeBackground: boolean
  changePlanModal: boolean
  purchaseDomain: boolean
  useTransactionBlockPlugin: boolean
  themeImportAi: boolean
  transactionalFilmstrip: boolean
  galleryV2: boolean
  popularBadge: 'plus' | 'pro' | null
  aiImagesDashboard: boolean
  pngExport: boolean
  googleOneTap: boolean
  pyimportPptNonVisualImport: boolean
}

export const FEATURE_FLAG_DEFAULTS: Readonly<FeatureFlags> = {
  debugCardViewed: false,
  debugComments: false,
  debugLogging: false,
  drawingBlock: true,
  tldraw2: true,
  uploadPDF: false,
  highlight: {
    enabled: false,
    enableStrictPrivacy: true,
  },
  realUserMetrics: false,
  // default sample rate is 100%
  rumSampleRate: 1,
  // default keystroke sample rate is 10%
  rumKeystrokeSampleRate: 10,
  cardViewedThreshold: 5000,
  channelActivity: false,
  customFonts: true,
  docImport: true,
  exportCard: true,
  presentModeFlat: true,
  blockReactions: true,
  intercom: true,
  shareFeedback: false,
  dataSyncErrorThreshold: -1,
  dataSyncClockDriftTolerance: 5,
  screenshotsEnabled: false,
  math: false,
  offline: false,
  offlineTest: false,
  cardWidthOptions: true,
  layoutResizing: true,
  tableOfContentsBlock: true,
  presentZoom: true,
  linkMetadata: false,
  presentFullWidth: false,
  presentScaleFont: false,
  renderCollapsedCards: true,
  cardLayoutsEnabled: true,
  presentFullScreen: false,
  docFullWidth: false,
  borderless: false,
  aiAutocomplete: false,
  aiRewrite: false,
  aiCardDesigner: false,
  aiWizard: true,
  aiWizardLegacy: false,
  aiWizardGenerateTimeout: 120000,
  aiWizardAdvanced: true,
  aiWizardOutlineMaxWait: 12000,
  aiWizardAverageWaitGuided: 20,
  aiWizardAverageWaitAdvanced: 60,
  aiWizardStreaming: true,
  aiWizardUnsplashImages: false,
  aiWizardGoogleImages: false,
  aiWizardBingImages: false,
  aiWizardWebImages: false,
  webImageProviderType: '2',
  aiWizardUploadImages: true,
  aiPremiumModels: false,
  aiChat: true,
  aiChatJsx: false,
  aiChatEditCardPrompt: false,
  aiThemeWizard: false,
  bulkUploadAccentImages: false,
  aiDebits: DEFAULT_CREDIT_COSTS,
  'ai-credits': {
    enabled: false,
    referralBonus: 100,
    signup: 400,
  },
  publishing: false,
  lexicaImageSearch: false,
  webImageSearch: false,
  docFormats: false,
  signupRedirectTo: 'HOME',
  openAiStatus: 'NORMAL',
  aiRequestTimeouts: {
    getOutlineForTopic: 4500, // Deprecated, use aiWizardOutlineMaxWait
    classify: 5000,
    basicReply: 5000,
    suggestContent: 12000,
    rateImages: 5000,
  },
  aiRequestRetries: {
    wizard: 0,
    wizardAdvanced: 0,
    wizardStreaming: 2,
    chat: 3,
  },
  docEditorForcePublicStatic: false,
  cardTextComputedEnabled: false,
  pptImport: true,
  pptImportDebug: false,
  hasProAccess: false,
  upsellUXEnabled_Pro: false,
  upsellUXEnabled_Plus: false,
  'plus-credits': {
    creditsToAdd: 400,
    enabled: false,
  },
  monthlyBillingEnabled: false,
  autoZoomToFit: true,
  azureOpenAI: true,
  passwordProtectedDoc: false,
  passwordSignup: true,
  docCustomCode: false,
  aiGeneratedImages: true,
  aiGeneratedImagesCount: 1,
  aiGeneratedImagesInWizard: true,
  aiGeneratedImagesDefaultModel: 'stable-diffusion-xl-v1-0',
  aiGeneratorDefaultImageProvider: 'web',
  aiGeneratorDefaultPresentationSettings: {},
  aiGeneratorDefaultWebpageSettings: {},
  aiGeneratedImagesAdvancedOptions: false,
  automatedImageProvider: false,
  permanentlyDeleteDoc: false,
  i18nEnabled: false,
  outageButterbar: {
    enabled: false,
  },
  filmstrip: true,
  streamResumingEnabled: true,
  sites: true,
  sitesNav: true,
  statsSmartLayouts: true,
  pyramidSmartLayouts: true,
  generatorLazyImages: true,
  generatorGPT4: false,
  generatorGPT4o: false,
  generatorBigModelAlways: false,
  imgProxy: true,
  recaptcha: {
    enabled: true,
    checkboxRiskLevels: ['medium'],
  },
  mobileEditing: false,
  churnkeyCancelFlowEnabled: false,
  churnkeyFailedPaymentWallEnabled: false,
  createGenerateV2: true,
  cardNotes: true,
  defaultAnnualBilling: true,
  editorAnimationsEnabled: false,
  editorAnimationsDefault: false,
  siteAnimationsEnabled: false,
  createSiteAi: true,
  generateParallel: false,
  generateParallelFree: false,
  generateSerial: false,
  anthropicGenerate: true,
  geminiGenerate: false,
  geminiProGenerate: false,
  geminiGenerateOutline: false,
  gpt4oMiniGenerate: false,
  gpt4oMiniGenerateLong: false,
  uploadThemeImagesToProd: false,
  workspaceSettings: true,
  addCard2: true,
  imagen: false,
  imagen3: false,
  imagenFlash: false,
  imagenFree: false,
  playgroundModel: true,
  playground3: false,
  flux1Schnell: false,
  flux1Dev: false,
  flux1Pro: false,
  ideogram: false,
  ideogramTurbo: false,
  ideogramTurboFree: false,
  ideogram2: false,
  sdxlModel: false,
  dalle3: false,
  dalle3Free: false,
  pptImportThemes: false,
  pptImportV2: false,
  importSlidePrompt: false,
  importUrl: false,
  importPdf: false,
  enableCharts: true,
  accentImageFit: true,
  cardTemplatesPlaceholder: false,
  hocuspocusOnAuthenticate: true,
  knownGoodHostnames: [],
  aiCardNotes: false,
  editCard2: false,
  editDocAI: false,
  changelog: false,
  filterContentErasingTrs: false,
  export: true,
  waitForGammaPersistenceMeta: true,
  linksV2: false,
  removeBackground: false,
  changePlanModal: true,
  purchaseDomain: false,
  useTransactionBlockPlugin: false,
  themeImportAi: false,
  transactionalFilmstrip: false,
  galleryV2: false,
  popularBadge: null,
  aiImagesDashboard: false,
  pngExport: false,
  googleOneTap: false,
  pyimportPptNonVisualImport: false,
}
