import { config } from 'config'
import { User } from 'modules/api'
import type { WorkspaceMembership } from 'modules/api/generated/graphql'
import { getIsOnline } from 'modules/connection/utils'
import { setOfflineUser } from 'modules/offline/manager/reducer'
import {
  getOrExpireOfflineUser,
  isOfflineModeEnabled,
} from 'modules/offline/manager/utils'
import { getStore } from 'modules/redux'

import { GraphqlUser } from '../context/UserContext'

type UserResponseLoggedIn = {
  status: 'loggedIn'
  user: GraphqlUser
}
type UserResponseError = {
  status: 'error'
  user: null
}
type UserResponseLoggedOut = {
  status: 'loggedOut'
  user: null
}

export type FetchUserResponse =
  | UserResponseLoggedIn
  | UserResponseError
  | UserResponseLoggedOut

const LOGGEDOUT_STATUS_CODES = [401, 403]

const transformToGraphqlUser = (user: User): GraphqlUser => {
  const { organizations, workspaceMemberships, ...rest } = user

  const _organizations = organizations?.map((o) => {
    return { ...o, id: o.id as string, __typename: 'Organization' as const }
  })

  const _workspaceMemberships = workspaceMemberships?.map((m) => {
    return {
      ...m,
      workspace: {
        ...m.workspace,
        __typename: 'Organization' as const,
      },
      __typename: 'WorkspaceMembership' as const,
    }
  }) as WorkspaceMembership[]

  // Coerce the data returned from /user to match
  // the shape of user objects from the GraphQL API
  // This is the only place in the codebase we should have to deal
  // with the /user shape. All others should use GraphqlUser
  return {
    ...rest,
    organizations: _organizations,
    workspaceMemberships: _workspaceMemberships,
    __typename: 'User',
  } as GraphqlUser
}

const backoff = async (base: number, retryCount: number) => {
  const waitTime = base * Math.pow(2, retryCount)
  return new Promise((resolve) => setTimeout(resolve, waitTime))
}

const doFetchUser = async (): Promise<FetchUserResponse> => {
  try {
    const req = await fetch(`${config.API_HOST}/user`, {
      credentials: 'include',
    })

    if (req.ok) {
      const user = (await req.json()) as User
      return {
        status: 'loggedIn',
        user: transformToGraphqlUser(user),
      }
    }

    if (LOGGEDOUT_STATUS_CODES.includes(req.status)) {
      return {
        status: 'loggedOut',
        user: null,
      }
    }

    return {
      status: 'error',
      user: null,
    }
  } catch (e) {
    return {
      status: 'error',
      user: null,
    }
  }
}

export const apiFetchUser = async ({
  returnOfflineUser = false,
  maxRetries = 3,
  retryCount = 0,
}: {
  maxRetries?: number
  returnOfflineUser?: boolean
  retryCount?: number
} = {}): Promise<FetchUserResponse> => {
  const store = getStore()
  const offlineEnabled = isOfflineModeEnabled()
  // if we're offline
  if (returnOfflineUser && offlineEnabled && !getIsOnline()) {
    const user = getOrExpireOfflineUser()
    if (user) {
      return {
        user,
        status: 'loggedIn',
      }
    }

    return {
      user: null,
      status: 'loggedOut',
    }
  }

  const response = await doFetchUser()

  if (response.status === 'error') {
    // we've gotten an error retrying the request
    // on the first error try to get the user from the offline manager
    if (offlineEnabled && !getIsOnline()) {
      const user = getOrExpireOfflineUser()
      if (user) {
        return {
          user,
          status: 'loggedIn',
        }
      }
    }

    // if offline mode isnt enabled, do a backoff retry to try to fetch
    // the user
    if (retryCount < maxRetries) {
      // for offline network errors wait a longer period between retries
      await backoff(1000, retryCount)
      return apiFetchUser({
        maxRetries,
        retryCount: retryCount + 1,
        returnOfflineUser,
      })
    }

    // other wise we've exhausted our retries we shouldn't do it again
    return {
      user: null,
      status: 'error',
    }
  }

  // if offline mode is enabled we we store the user response in the offline manager
  if (offlineEnabled) {
    if (response.status === 'loggedIn') {
      // if we get a user from the api, set it in the offline manager
      store.dispatch(
        setOfflineUser({
          user: response.user,
          lastSynced: new Date().toISOString(),
        })
      )
    } else if (response.status === 'loggedOut') {
      // if we get a logged out user, clear the offline user
      store.dispatch(
        setOfflineUser({
          user: null,
          lastSynced: new Date().toISOString(),
        })
      )
    }
  }

  return response
}
